<template>
  <div class="checkout-cart-bag__list-container">
    <TransitionGroup
      :id="`j-checkout-bag__${mallCode}-${swiperId}`"
      tag="swiper-container"
      :name="transitionName" 
      init="false"
      :class="['checkout-cart__list-body', mallCode_quickShip]"
      :dir="GB_cssRight ? 'rtl' : 'ltr'"
      destroy-on-disconnected="false"
      @mouseenter="showSwiperButton=true"
      @mouseleave="showSwiperButton=false"
    >
      <swiper-slide
        v-for="(item, index) in cartsData"
        :id="`checkout-cart-item-${item.id}-${swiperId}`"
        :key="`checkout-cart-item-${item.id}`"
        class="checkout-cart-item__slide"
      >
        <checkout-cart-item
          ref="checkoutCartRef"
          :chemicals-ids="chemicalsIds"
          :tag-ids-map="tagIdsMap"
          :item="item"
          :index="index"
          :language="language"
          :checkout="checkout"
          :can-edit-shoppingbag="canEditShoppingbag"
          :is-last-goods="isLastGoods"
          source="outer"
          :shopping-bag-crossed-price-by-abt="shoppingBagCrossedPriceByAbt"
          :discount-logo-by-abt="discountLogoByAbt"
          :under-price-abt-value="underPriceAbtValue"
          :is-suggested="isSuggested"
          :checkout-scene="checkoutScene"
          :locals="locals"
          :switch-on-evolu-shein="switchOnEvoluSHEIN"
          :evolu-shein-id="EVOLU_SHEIN_ID"
          :show-arrival-price-by-abt="showArrivalPriceByAbt"
          :large-ship-extends-info="largeShipExtendsInfo"
          :is-site-mall-list="isSiteMallList"
          :sfs-tag-abt="CartOrderSFSVisual"
        />
      </swiper-slide>
      <!-- eslint-disable vue/no-deprecated-slot-attribute -->
      <div
        slot="container-start"
        :key="`checkout-cart-item`"
      >
        <div
          v-show="showSwiperButton"
          :class="['swiper-button-prev', 'common-button-style', `swiperv2-checkout-${mallCode}-${swiperId}-prev`]"
        >
          <Icon
            name="sui_icon_more_left_18px"
            size="18px"
            :is-rotate="GB_cssRight"
          />
        </div>
      </div>
      <div
        slot="container-end"
        :key="`checkout-cart-item`"
      >
        <div
          v-show="showSwiperButton"
          :class="['swiper-button-next', 'common-button-style', `swiperv2-checkout-${mallCode}-${swiperId}-next`]"
        >
          <Icon
            name="sui_icon_more_right_18px"
            size="18px"
            :is-rotate="GB_cssRight"
          />
        </div>   
      </div>
    </transitiongroup>
  </div>
</template>

<script>
import CheckoutCartItem from 'public/src/pages/checkout/vue_tpls/shopping_bag/CheckoutCartItem.vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { mapState, mapMutations, mapGetters } from 'vuex'
import { Navigation } from 'swiper/modules'
import { register } from 'swiper/element'

typeof window !== 'undefined' && register()

export default {
  name: 'CheckoutCartList',
  components: {
    CheckoutCartItem,
    Icon,
  },
  props: {
    cartsData: {
      type: Array,
      default: () => []
    },
    chemicalsIds: {
      type: Array,
      default: () => []
    },
    tagIdsMap: {
      type: Object,
      default: () => {}
    },
    canEditShoppingbag: {
      type: Boolean,
      default: false
    },
    isLastGoods: {
      type: Boolean,
      default: false
    },
    shoppingBagCrossedPriceByAbt: {
      type: Boolean,
      default: false
    },
    discountLogoByAbt: {
      type: Boolean,
      default: false
    },
    underPriceAbtValue: {
      type: Boolean,
      default: false
    },
    isSuggested: {
      type: [Boolean, String],
      default: false
    },
    checkoutScene: {
      type: String,
      default: ''
    },
    // 当前购物袋代表的是qs还是非qs
    isQsProduct: {
      type: String,
      default: '0'
    },
    largeShipExtendsInfo: {
      type: Object,
      default: () => {}
    },
    isSiteMallList: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showSwiperButton: false,
      navSwiper: null,
      transitionName: '',
    }
  },
  // 该文件为复用组件，尽量不要从store里传值过来
  computed: {
    ...mapState([
      'checkout', 
      'language',
      'editCartInfo',
      'locals',
    ]),
    ...mapGetters([
      'switchOnEvoluSHEIN',
      'EVOLU_SHEIN_ID',
      'showArrivalPriceByAbt',
      'CartOrderSFSVisual'
    ]),
    GB_cssRight() {
      return this.locals?.GB_cssRight ?? gbCommonInfo?.GB_cssRight
    },
    mallCode() {
      return this.cartsData?.[0]?.mall_code
    },
    //增加mallcode quickShip
    mallCode_quickShip(){
      const { mall_code } = this.cartsData[0] || {}
      return `_current_dom_together_buy_mall${mall_code}_quick${this.isQsProduct}`
    },
    swiperId() {
      const noQuickship = this.cartsData.filter(item => item.quick_ship != 1 || item?.over_quick_ship == 1) || []
      return !!noQuickship.length ? 'noquickship' : 'quickship'
    },
  },
  watch: {
    cartsData: {
      handler(n, o) {
        if(n != o) {
          if(this.navSwiper?.swiper) this.navSwiper.swiper.update()
        }
      },
      deep: true
    },
  },
  mounted() {
    this.initSwiper()
    this.$nextTick(() => {
      // 避免首次渲染时购物袋元素参与动画效果
      this.transitionName = 'fadeedit'
    })
  },
  methods: {
    ...mapMutations(['changeParamsStatus']),
    initSwiper: function () {
      const delInx = this.handleDelItem()

      this.$nextTick(() => {
        this.navSwiper = document.querySelector(`#j-checkout-bag__${this.mallCode}-${this.swiperId}`)
        if (this.navSwiper !== null) {
          let swiperParams = {
            slidesPerView: 'auto',
            slidesPerGroup: 1,
            normalizeSlideIndex: false,
            modules: [Navigation],
            navigation: {
              nextEl: `.swiperv2-checkout-${this.mallCode}-${this.swiperId}-next`,
              prevEl: `.swiperv2-checkout-${this.mallCode}-${this.swiperId}-prev`
            },
            on: {
              beforeTransitionStart: () => {
                if(this.$refs.checkoutCartRef){
                  if( Array.isArray(this.$refs.checkoutCartRef)){
                    this.$refs.checkoutCartRef.forEach(item => {
                      item.changeDelectBubble(false)
                    })
                  } else {
                    this.$refs.checkoutCartRef.changeDelectBubble(false)
                  }
                }
              },
            }
          }
          if (delInx != -1) swiperParams.initialSlide = delInx
          Object.assign(this.navSwiper, swiperParams)
          this.navSwiper.initialize()
        }
      })
    },
    handleDelItem() {
      let delInx = -1
      if(this.editCartInfo?.delItemInfo?.id) {
        delInx = this.editCartInfo?.delItemInfo?.index
        const swiperId = !this.editCartInfo.delItemInfo.quick_ship ? 'noquickship' : 'quickship'
        if(document.getElementById(`checkout-cart-item-${this.editCartInfo.delItemInfo.id}-${swiperId}`)){
          document.getElementById(`checkout-cart-item-${this.editCartInfo.delItemInfo.id}-${swiperId}`).style.opacity = 0
        }
        this.changeParamsStatus({ 
          type: 'editCartInfo', 
          params: { 
            delItemInfo: {}
          } 
        })
      }

      return delInx
    },
  },
}
</script>

<style lang="less" scoped>
.checkout-cart__list-body {
  .fadeedit-move,
  .fadeedit-leave-active {
    transition: all 0.3s ease-in;
  }
  .fadeedit-enter-active {
    opacity: 0;
    scale: 0.1;
  }
  .fadeedit-enter-to {
    opacity: 1;
    scale: 1;
    transition: all 0.3s ease-in;
  }
  .fadeedit-leave-active {
    opacity: 0;
    z-index: -1;
    transform: translateX(-90px);
  }
}

.common-button-style{
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(255, 255, 255, .94);
  border-radius: 50%;
  z-index: 12;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .08);
  color: #222;
  font-size: 16px;
}
</style>

<style lang="less">
// 此处不要加scoped，SSR阶段TransitionGroup获取样式会失效
.checkout-cart-bag__list-container {
  .checkout-cart__list-body {
    display: flex;
    padding: 0;
    margin: 0;
    align-items: stretch;
    width: auto;
    height: unset;
    overflow: hidden;
  }
  .checkout-cart-item__slide{
    width: auto;
    display: flex;
    height: auto;
  }
}
</style>
