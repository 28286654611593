<template>
  <div v-if="cartModalList && cartModalList.length">
    <!-- 新版购物袋弹窗  -->
    <s-drawer
      v-if="isNewShopBagPopTypeByAbt"
      v-model:visible="shoppingBagModalShow"
      class="shopbag-coupon-drawer"
      direction="rtl"
      size="400px"
      :append-to-body="true"
      @opened="drawerOpened = true"
    >
      <template #title>
        <strong
          class="bag-list-title"
        >
          {{
            language.SHEIN_KEY_PC_25384
          }}
        </strong>
        <div class="sub-title">
          <!-- 标签 1.轮播 2.跑马 -->
          <bagsHeader
            v-if="!!swiperTitleConfig?.length && shoppingBagModalShow && ShoppingBagStyleAbt != 'Hide'"
            ref="bagsHeaderRef"
            :swiper-title-config="swiperTitleConfig"
          />
        </div>
        <div class="sub-title-bottom">
          <CheckoutCartListTips 
            class="shopping-bag-list__tips-v2"
            :mall-code="mallCode"
            :checkout-scene="checkoutScene"
            :default-shipping="defaultShipping"
            :quick-ship-checkout-status="quickShipCheckoutStatus"
            :desc-quick-ship-time="descQuickShipTime"
            :lagre-ship-info="lagreShipInfo"
            :cart-modal-list="cartModalList"
            :drawer-opened="drawerOpened"
          />
        </div>
      </template>
      <div
        v-if="mergeCartsList && mergeCartsList.length"
        class="shopbag-drawer-list"
      >
        <template
          v-for="(eachStore, index) in mergeCartsList"
          :key="index"
        >
          <div class="shopbag-list-body">
            <div
              class="shopbag-list-content"
            >
              <div
                :key="`bag-title-${index}`"
                class="shop-platform-title"
              >
                <div class="store-business-wrap">
                  <strong class="store-info-content">
                    <preferred-seller-store-icon v-if="isPreferredSellerStore(eachStore)" />
                    <template v-else>
                      <Icon
                        v-if="!IS_RW && storeIconChangeByAbt" 
                        class="store-info-content__icon"
                        name="sui_icon_store_16px" 
                        size="16px" 
                        color="#222222"
                      />
                      <Icon
                        v-else
                        name="sui_icon_store_20px"
                        size="20px"
                        color="#303030"
                      />
                    </template>
                    <span
                      class="store-name store-name-new"
                    >
                      {{ eachStore[0].store_title || eachStore[0].store_name }}
                    </span>
                  </strong>
                  <img
                    v-if="eachStore[0].storeTrendLogo"
                    class="trend-logo"
                    :src="eachStore[0].storeTrendLogo"
                  />
                  <div 
                    v-if="showStoreSellerInfo"
                    v-tap="analysisData('1-11-2-2', eachStore[0].store_code)" 
                    v-expose="analysisData('1-11-2-1', eachStore[0].store_code)"
                    class="business-info-wrap"
                    @click="openMerchantInfoModal(eachStore[0])"
                  >
                    <span>{{ language.SHEIN_KEY_PC_25988 }}</span>
                    <Icon
                      class="business-info-wrap__icon"
                      name="sui_icon_doubt_12px_2"
                      size="10px"
                      color="#959595"
                    />
                  </div>
                </div>
                <span class="store-product-total">{{ cartProductTotal(eachStore) }}</span>
              </div>
              <div
                :key="`content-${index}`"
                class="carts-container"
              >
                <checkout-cart-item
                  v-for="(item, inx) in eachStore"
                  :key="`checkout-cart-item-${inx}`"
                  :chemicals-ids="chemicalsIds"
                  :tag-ids-map="tagIdsMap"
                  :item="item"
                  :is-bag-modal="true"
                  :index="inx"
                  :language="language"
                  :shopping-bag-modal-show="shoppingBagModalShow"
                  :checkout="checkout"
                  :can-edit-shoppingbag="canEditShoppingbag"
                  :is-last-goods="isLastGoods"
                  source="inner"
                  :shopping-bag-crossed-price-by-abt="shoppingBagCrossedPriceByAbt"
                  :discount-logo-by-abt="discountLogoByAbt"
                  :under-price-abt-value="underPriceAbtValue"
                  :is-new-shop-bag-pop-type-by-abt="isNewShopBagPopTypeByAbt"
                  :is-suggested="isSuggested"
                  :checkout-scene="checkoutScene"
                  :locals="locals"
                  :switch-on-evolu-shein="switchOnEvoluSHEIN"
                  :evolu-shein-id="EVOLU_SHEIN_ID"
                  :show-arrival-price-by-abt="showArrivalPriceByAbt"
                  :large-ship-extends-info="largeShipExtendsInfo"
                  :is-site-mall-list="isSiteMallList"
                  :sfs-tag-abt="CartOrderSFSVisual"
                />
              </div>
            </div>
          </div>
        </template>
      </div>
    </s-drawer>
    <!-- 旧版购物袋弹窗 -->
    <s-dialog
      v-else
      v-model:visible="shoppingBagModalShow"
      :type="'W480'"
      :show-close="true"
      :close-on-click-modal="false"
      :is-scroll="true"
      append-to-body
      class="bag-list__effiency"
      @opened="drawerOpened = true"
    >
      <template #title>
        {{ language.SHEIN_KEY_PC_25384 }}
      </template>
      <template #subtitle>
        <!-- 标签 1.轮播 2.跑马 -->
        <bagsHeader
          v-if="!!swiperTitleConfig?.length && shoppingBagModalShow && ShoppingBagStyleAbt != 'Hide'"
          ref="bagsHeaderRef"
          :swiper-title-config="swiperTitleConfig"
        />

        <CheckoutCartListTips 
          class="shopping-bag-list__tips-v1"
          :mall-code="mallCode"
          :checkout-scene="checkoutScene"
          :default-shipping="defaultShipping"
          :quick-ship-checkout-status="quickShipCheckoutStatus"
          :desc-quick-ship-time="descQuickShipTime"
          :lagre-ship-info="lagreShipInfo"
          :cart-modal-list="cartModalList"
          :drawer-opened="drawerOpened"
        />
      </template>

      <div
        v-if="mergeCartsList && mergeCartsList.length"
        class="shopping-bag-list"
      >
        <template
          v-for="(eachStore, index) in mergeCartsList"
          :key="index"
        >
          <div class="bag-list-body">
            <div
              class="bag-list-content j-bag-list-content"
            >
              <div
                :key="`title-${index}`"
                class="platform-title"
              >
                <div class="store-business-container">
                  <strong class="store-info-content">
                    <preferred-seller-store-icon v-if="isPreferredSellerStore(eachStore)" />
                    <template v-else>
                      <Icon
                        v-if="!IS_RW && storeIconChangeByAbt" 
                        class="store-info-content__icon"
                        name="sui_icon_store_16px" 
                        size="16px" 
                        color="#222222"
                      />
                      <Icon
                        v-else
                        name="sui_icon_store_20px"
                        size="20px"
                        color="#303030"
                      />
                    </template>
                    {{ eachStore[0].store_title || eachStore[0].store_name }}
                  </strong>
                  <img
                    v-if="eachStore[0].storeTrendLogo"
                    class="trend-logo"
                    :src="eachStore[0].storeTrendLogo"
                  />
                  <div 
                    v-if="showStoreSellerInfo"
                    v-tap="analysisData('1-11-2-2', eachStore[0].store_code)" 
                    v-expose="analysisData('1-11-2-1', eachStore[0].store_code)"
                    class="business-info-content"
                    @click="openMerchantInfoModal(eachStore[0])"
                  >
                    <span>{{ language.SHEIN_KEY_PC_25988 }}</span>
                    <Icon
                      class="business-info-content__icon"
                      name="sui_icon_doubt_12px_2"
                      size="12px"
                      color="#222222"
                    />
                  </div>
                </div>
                <span style="white-space: nowrap;">{{ cartProductTotal(eachStore) }}</span>
              </div>
              <div
                :key="`content-${index}`"
                class="carts-wrapper"
              >
                <checkout-cart-item
                  v-for="(item, inx) in eachStore"
                  :key="`checkout-cart-item-${inx}`"
                  :chemicals-ids="chemicalsIds"
                  :tag-ids-map="tagIdsMap"
                  class="carts-item-effiency old-carts-item_style"
                  :item="item"
                  :is-bag-modal="true"
                  :index="inx"
                  :language="language"
                  :shopping-bag-modal-show="shoppingBagModalShow"
                  :checkout="checkout"
                  :can-edit-shoppingbag="canEditShoppingbag"
                  :is-last-goods="isLastGoods"
                  source="inner"
                  :shopping-bag-crossed-price-by-abt="shoppingBagCrossedPriceByAbt"
                  :discount-logo-by-abt="discountLogoByAbt"
                  :under-price-abt-value="underPriceAbtValue"
                  :is-suggested="isSuggested"
                  :checkout-scene="checkoutScene"
                  :locals="locals"
                  :switch-on-evolu-shein="switchOnEvoluSHEIN"
                  :evolu-shein-id="EVOLU_SHEIN_ID"
                  :show-arrival-price-by-abt="showArrivalPriceByAbt"
                  :large-ship-extends-info="largeShipExtendsInfo"
                  :is-site-mall-list="isSiteMallList"
                  :sfs-tag-abt="CartOrderSFSVisual"
                />
              </div>
            </div>
          </div>
        </template>
      </div>
    </s-dialog>
    <s-dialog
      v-model:visible="merchantInfoModalShow"
      :show-close="true"
      :close-on-click-modal="false"
    >
      <template #title>
        {{ language.SHEIN_KEY_PC_25988 }}
      </template>
      <div class="merchant-content">
        <div class="merchant-content-item">
          {{ language.SHEIN_KEY_PC_25994 }}
          <span 
            class="item-val"
            :class="{'is-block': bussinessName}"
          >{{ bussinessName || language.SHEIN_KEY_PC_25999 }}</span>
        </div>
        <div class="merchant-content-item">
          {{ language.SHEIN_KEY_PC_25990 }}
          <span 
            class="item-val" 
            :class="{'is-block': bussinessAddress}"
          >{{ bussinessAddress || language.SHEIN_KEY_PC_25999 }}</span>
        </div>
        <div class="merchant-content-item">
          {{ language.SHEIN_KEY_PC_25996 }}
          <span
            class="item-val"
            :class="{'is-block': bussinessRegistrationNumber}"
          >{{ bussinessRegistrationNumber || language.SHEIN_KEY_PC_26000 }}</span>
        </div>
        <div class="merchant-content-item">
          {{ language.SHEIN_KEY_PC_25998 }}
          <span
            class="item-val"
            :class="{'is-block': vatNumber}"
          >{{ vatNumber || language.SHEIN_KEY_PC_26000 }}</span>
        </div>
        <p class="desc">
          {{ language.SHEIN_KEY_PC_26003 }}
        </p>
        <p class="desc">
          {{ language.SHEIN_KEY_PC_26004 }}
        </p>
      </div>
      <template #footer>
        <SButton 
          :type="['primary']"
          @click="closeMmerchantInfoModalShow"
        >
          {{ language.SHEIN_KEY_PC_15216 }} 
        </SButton>
      </template>
    </s-dialog>
  </div>
</template>

<script>
import CheckoutCartItem from 'public/src/pages/checkout/vue_tpls/shopping_bag/CheckoutCartItem.vue'
import preferredSellerStoreIcon from 'public/src/pages/cart_new/components/preferredSellerStoreIcon.vue'
import CheckoutCartListTips from 'public/src/pages/checkout/vue_tpls/shopping_bag/CheckoutCartListTips.vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { handleLargeShipInfo } from 'public/src/pages/checkout/config/tools.js'
import { filterPromitionGoods } from 'public/src/pages/checkout/utils.js'
import useLowStock from 'public/src/pages/checkout/hooks/useLowStock.js'
import { useMapGetters, useMapState } from 'public/src/pages/store_pages/store/mapHook'
import { ref, watch } from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { template } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter'
import { exposeScenesAbtAggregator } from 'public/src/pages/checkout/hooks/EventAggregator.js'
import bagsHeader from 'public/src/pages/checkout/components/atom/bagsHeader.vue'
import { useHeaderTitle } from 'public/src/pages/checkout/vue_tpls/hook/useHeaderTitle.js'

daEventCenter.addSubscriber({ modulecode: '1-7-5' })
daEventCenter.addSubscriber({ modulecode: '1-7-7' })
daEventCenter.addSubscriber({ modulecode: '1-7-1' })
daEventCenter.addSubscriber({ modulecode: '1-11-2' })

export default {
  name: 'ShoppingBagList',
  components: {
    CheckoutCartItem,
    preferredSellerStoreIcon,
    Icon,
    CheckoutCartListTips,
    bagsHeader,
  },
  setup(props) {
    const { outOfStockTips } = useMapGetters(['outOfStockTips']) 
    const lowStockProducts = ref([])

    const swiperTitleConfig = ref()
    const bagsHeaderRef = ref(null)
    const { language } = useMapState(['language'])

    watch(
      () => props.cartModalList, 
      (carts) => {
        const lowStockInfo = useLowStock({ 
          abt: outOfStockTips.value || {}, 
          carts 
        })
        lowStockProducts.value = lowStockInfo.lowStockProducts.value

        swiperTitleConfig.value = useHeaderTitle({
          carts,
          lowStockProducts: lowStockProducts.value,
          languge: language.value
        })
      },
      { 
        immediate: true,
        deep: true 
      }
    )
    return {
      lowStockProducts,

      swiperTitleConfig,
      bagsHeaderRef,
    }
  },
  props: {
    cartModalList: {
      type: Array,
      default: () => [],
    },
    cartsMallInfo: {
      type: Object,
      default: () => {},
    },
    chemicalsIds: {
      type: Array,
      default: () => [],
    },
    tagIdsMap: {
      type: Object,
      default: () => {},
    },
    descQuickShipTime: {
      type: String,
      default: ''
    },
    canEditShoppingbag: {
      type: Boolean,
      default: false
    },
    shoppingBagCrossedPriceByAbt: {
      type: Boolean,
      default: false
    },
    discountLogoByAbt: {
      type: Boolean,
      default: false
    },
    underPriceAbtValue: {
      type: Boolean,
      default: false
    },
    isSuggested: {
      type: [Boolean, String],
      default: false
    },
    checkoutScene: {
      type: String,
      default: ''
    },
    largeShipExtendsInfo: {
      type: Object,
      default: () => {}
    },
    isSiteMallList: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      shoppingBagModalShow: false,
      merchantInfoModalShow: false,
      merchantInfoStore: {},
      quickShipTextRef: 'EffiencyBagList~vueshopping-bag__tips_flex',
      quickShipLineHeight: 14,
      quickShipFontSizeSmall: '11px', // 尝试过按照设计稿10px，太小了眼睛都瞎了
      drawerOpened: false
    }
  },
  computed: {
    ...mapState([
      'defaultShipping', 
      'checkout',
      'language',
      'locals',
    ]),
    ...mapGetters([
      'userBehaviorAbt', 
      'quickShipCheckoutStatus', 
      'switchOnEvoluSHEIN', 
      'EVOLU_SHEIN_ID', 
      'showArrivalPriceByAbt',
      'storeIconChangeByAbt',
      'isNewShopBagPopTypeByAbt',
      'CartOrderSFSVisual',
      'ShoppingBagStyleAbt'
    ]),
    IS_RW() {
      return this.locals.IS_RW
    },
    mallCode() {
      return this.cartModalList?.[0]?.mall_code || ''
    },
    isLastGoods() {
      // 是否是最后一个商品（当前结算车内最后一个，并非当前mall），最后一个商品去除赠品 ,qs拆车数据算同个商品
      const otherCarts = this.checkout.results?.carts?.carts?.filter(item => item.mall_code != this.mallCode)
      const otherCartsWithoutPromition = filterPromitionGoods(otherCarts)

      const originList = this.mergeCartsList
      const list = originList.map(store => {
        return filterPromitionGoods(store)
      })?.filter(store => !!store.length) || []

      return otherCartsWithoutPromition.length == 0 && (list.length <= 1 && list?.[0]?.length <= 1)
    },
    showObmByAbt() {
      return this.checkout.checkoutBFFAbtInfo?.showOBMTitle?.param == 'conceal_OBM' ? false : true
    },
    mergeCartsList() {
      const originCarts = JSON.parse(JSON.stringify(this.cartModalList))

      let selfCartsList = []
      let storeCartsList = []
      let selfCarts = {}
      let storeCarts = {}

      if(this.showObmByAbt) {
        selfCartsList = []

        originCarts.forEach(item => {
          if(!storeCarts['s_' + item.store_code]){
            storeCarts['s_' + item.store_code] = []
          }
          storeCarts['s_' + item.store_code].push(item)
        })
        storeCartsList = Object.values(storeCarts) || []

      } else {
        // 展示OBM的场景，直接取后端返回的店铺名称; 不展示OBM的分支，前端写死SHEIN的店铺名称(obm的店铺名称不是SHEIN)
        // obm与自营店铺合并展示
        selfCartsList = originCarts.filter(item => item.business_model == 0) || []
        if(!!selfCartsList.length) {
          selfCarts['shein'] = selfCartsList.map(item => {
            return Object.assign({}, item, { store_title: this.language.SHEIN_KEY_PC_21125 })
          })
          selfCartsList = Object.values(selfCarts) || []
        }

        originCarts.forEach(item => {
          if(item.business_model != 0) {
            if(!storeCarts['s_' + item.store_code]){
              storeCarts['s_' + item.store_code] = []
            }
            storeCarts['s_' + item.store_code].push(item)
          }
        })
        storeCartsList = Object.values(storeCarts) || []
      }

      // 二次下单页接口无加车时间返回，直接按上述顺序展示
      const sortList = [].concat(selfCartsList).concat(storeCartsList).sort((a, b) => {
        const add_time_a = new Date(a?.[0]?.add_time).getTime()
        const add_time_b = new Date(b?.[0]?.add_time).getTime()
        return add_time_b - add_time_a
      })
      return sortList
    },
    showStoreSellerInfo(){
      return this.checkout.showStoreSellerInfoAbt
    },
    storeSellerInfo(){
      return this.checkout.storeSellerInfo?.storeSellerInfo || {}
    },
    isSelfStore() {
      return this.merchantInfoStore.business_model == 0
    },
    is1PStore() {
      return this.merchantInfoStore.business_model == 4
    },
    sellerInfo() {
      return this.storeSellerInfo[this.merchantInfoStore.store_code] || {}
    },
    bussinessName(){
      if(this.isSelfStore || this.is1PStore) {
        return this.checkout.SELF_STORE_SELLER?.bussinessName
      }
      let bussinessNameInfo = this.sellerInfo.fieldValues?.find(item => item.fieldType == 3 && item.fieldValue) || null
      bussinessNameInfo = bussinessNameInfo ? bussinessNameInfo : this.sellerInfo.fieldValues?.find(item => item.fieldType == 4 && item.fieldValue) || null
      return bussinessNameInfo ? bussinessNameInfo.fieldValue : ''
    },
    bussinessAddress(){
      if(this.isSelfStore || this.is1PStore) {
        return this.checkout.SELF_STORE_SELLER?.bussinessAddress
      }
      let bussinessNameInfo = this.sellerInfo.fieldValues?.find(item => item.fieldType == 6 && item.fieldValue) || null
      return bussinessNameInfo ? bussinessNameInfo.fieldValue : ''
    },
    bussinessRegistrationNumber(){
      if(this.isSelfStore || this.is1PStore) {
        return this.checkout.SELF_STORE_SELLER?.registrationNumber
      }
      return this.sellerInfo.codeRegisterNo || ''
    },
    vatNumber(){
      if(this.isSelfStore || this.is1PStore) {
        return this.checkout.SELF_STORE_SELLER?.vatNumber
      }
      if(this.sellerInfo.taxInfoList?.length) {
        if(this.sellerInfo.taxInfoList.length == 1) {
          return this.sellerInfo.taxInfoList[0].supplierTaxNumber || ''
        } else {
          let vatInfo = this.sellerInfo.taxInfoList.find(item => item.saleCountry == 'FR' && item.supplierTaxNumber) || null
          vatInfo = vatInfo ? vatInfo : this.sellerInfo.taxInfoList.find(item => item.saleCountry == 'DE' && item.supplierTaxNumber) || null
          vatInfo = vatInfo ? vatInfo : this.sellerInfo.taxInfoList.find(item => item.supplierTaxNumber) || null
          return vatInfo ? vatInfo.supplierTaxNumber : ''
        }
      }
      return ''
    },
    // showSensitiveTips() {
    //   let show = false
    //   if(this.checkoutScene != 'Checkout') {
    //     // 二次下单页
    //     this.checkout?.results?.mallShippingOrderMethod?.mall_list?.forEach(item => {
    //       if (item.mall_code === this.cartsMallInfo.mall_code) {
    //         show = !!item.shipping_methods[0]?.cmmdty_infos?.length
    //       }
    //     })
    //   } else {
    //     // 下单页
    //     this.defaultShipping?.forEach(item => {
    //       if (item.mall_code === this.cartsMallInfo.mall_code) {
    //         show = !!item.shipping_method.cmmdty_infos?.length
    //       }
    //     })
    //   }
      
    //   return show
    // },
    almostSoldoutTips() {
      const num = this.lowStockProducts?.length || 0
      if (num < 1) return ''
      return this.template(
        num,
        `<font color='#FE3B30'>${this.language.SHEIN_KEY_PC_29808}</font>`,
        this.language.SHEIN_KEY_PC_29807
      )
    },
    // announcements() {
    //   let tips = []
    //   if(this.quickShipCheckoutStatus.quickShipStyle){
    //     if (this.descQuickShipTime) {
    //       tips.push({
    //         icon: {
    //           type: 'quickShip',
    //           style: {
    //             'color': '#198055',
    //             'background-color': 'transparent',
    //             'font-size': '12px',
    //             'icon-size': '16px',
    //             'font-weight': '700',
    //             'padding-left': 0,
    //           },
    //           label: this.quickShipCheckoutStatus.quickShipLabelText
    //         },
    //         text: this.descQuickShipTime
    //       })
    //     }
    //   } else {
    //     if (this.descQuickShipTime) tips.push({
    //       label: this.quickShipCheckoutStatus.quickShipLabelText,
    //       labelType: 'success',
    //       text: this.descQuickShipTime
    //     })
    //   }
    //   return tips
    // },
    showBehaviorLabelByAbt() {
      const  CheckoutBehaviorShow = 'fivestar,adds,sales2,buyagain,lowestprice'
      return !!CheckoutBehaviorShow && CheckoutBehaviorShow != 'Hide'
    },
    lagreShipInfo() {
      const { largeShipTimeDesc = '', largeShipTitle = '', largeShipGoods = [], largeShipType = '', largeShipIcon = '' } = handleLargeShipInfo({ 
        isSiteMallList: this.isSiteMallList, 
        largeShipExtendsInfo: this.largeShipExtendsInfo, 
        mallCode: this.mallCode, 
        carts: this.cartModalList,
        isOrder: this.checkoutScene == 'CheckoutAgain',
      })
      return {
        largeShipTimeDesc,
        largeShipTitle,
        largeShipGoods,
        largeShipType,
        largeShipIcon,
      }
    },
  },
  watch: {
    shoppingBagModalShow: {
      handler(show) {
        if (show) {
          this.handleBehaviorReport()
          this.handleAlmostSoldoutReport()
          this.handleTrendStoreReport()
          this.$nextTick(() => {
            this.handleQuickTextStyle()
          })

          setTimeout(() => {
            this.$refs?.bagsHeaderRef && this.$refs?.bagsHeaderRef?.updateScrollTextStyle()
            this.shoppingBagHeadelineReport()
          }, 500)
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations(['assignState']),
    template,
    init() {
      this.shoppingBagModalShow = true
      this.$nextTick(() => {
        let insured_goods =
          this.cartModalList?.filter((item) => item.product.insurable == 1) || []
        insured_goods.length &&
          daEventCenter.triggerNotice({
            daId: '1-7-5-21',
            extraData: {
              insured_goods: insured_goods.map(
                (item) => item.product.insurable
              ),
            },
          })
        let flashTypes = this.cartModalList?.map((item) => item.flashType) || []
        flashTypes.length &&
          daEventCenter.triggerNotice({
            daId: '1-7-7-18',
            extraData: {
              flashTypes: flashTypes,
            },
          })
        let storeCarts = this.mergeCartsList
        storeCarts.forEach( carts => {
          daEventCenter.triggerNotice({
            daId: '1-7-1-18',
            extraData: {
              store_code: carts[0].store_code,
              store_tp: carts[0].store_type == 1 ? 1 : 2,
              preferred_seller: this.isPreferredSellerStore(carts) ? 1 : 0,
              store_label: carts[0].storeTrendLogo ? 'trends' : '-'
            }
          })
        })
      })
    },
    // showQuickPop(flag) {
    //   flag && this.assignState({
    //     isShowQuickPop: true
    //   })
    // },
    cartProductTotal: function (carts) {
      if (!carts.length) return ''
      let total = carts.reduce((total, item) => {
        total = total + Number(item.quantity)
        return total
      }, 0)
      return total > 1
        ? total + ' ' + this.language.SHEIN_KEY_PC_15448
        : total + ' ' + this.language.SHEIN_KEY_PC_15447
    },
    isPreferredSellerStore (carts){
      let field = this.checkoutScene != 'Checkout' ? 'preference_seller' : 'preferred_seller_store'
      return carts.find( cart => cart[field] == 1) ? true : false
    },
    openMerchantInfoModal (store) {
      this.merchantInfoStore = store
      this.merchantInfoModalShow = true
      daEventCenter.triggerNotice({
        daId: '1-11-2-3',
        extraData: {
          store_code: store.store_code
        }
      })
    },
    closeMmerchantInfoModalShow () {
      this.merchantInfoModalShow = false
      daEventCenter.triggerNotice({
        daId: '1-11-2-4',
        extraData: {
          store_code: this.merchantInfoStore.store_code
        }
      })
    },
    analysisData (id, store_code) {
      return {
        id: id,
        once: false,
        data: {
          store_code,
        }
      }
    },
    handleBehaviorReport() {
      let hasBehavior = this.cartModalList.some(cart => cart.product.showBehaviorLabel)
      let type
      if (this.showBehaviorLabelByAbt) {
        type = hasBehavior ? 3 : 1
      } else {
        type = hasBehavior ? 4 : 2
      }
      exposeScenesAbtAggregator.addEvent({
        daId: '1-11-1-169',
        extraData: {
          scenes: 'userbehavior_tips',
          type
        }
      })
    },
    handleAlmostSoldoutReport() {
      // const num = this.lowStockProducts?.length || 0
      this.swiperTitleConfig?.length && daEventCenter.triggerNotice({
        daId: '1-11-2-13',
        extraData: {
          // headline_type: `almost_soldout,${num}`,
          headline_type: this.swiperTitleConfig.map(m => m.type).join(','),
          is_sealand: !!this.lagreShipInfo?.largeShipTimeDesc && !!this.lagreShipInfo?.largeShipGoods?.length ? 1 : 0, // 是1、否0
        }
      })
    },
    shoppingBagHeadelineReport(){
      const hasAbt = this.ShoppingBagStyleAbt !== 'Hide'
      const hasBenefit = this.swiperTitleConfig?.length
      let type
      if (hasBenefit) {
        type = hasAbt ? '3' : '4'
      } else {
        type = hasAbt ? '1' : '2'
      }
      exposeScenesAbtAggregator.addEvent({
        daId: '1-11-1-169',
        extraData: {
          scenes: 'shoppingbag_headline_tips',
          type
        }
      })
    },
    handleTrendStoreReport () {
      const trendStore = this.mergeCartsList.filter(store => !!store[0]?.trendStoreCodes)
      if (!trendStore.length) return
      exposeScenesAbtAggregator.addEvent({
        daId: '1-11-1-169',
        extraData: {
          scenes: 'store_trends_logo',
          type: trendStore[0][0].trendStoreCodes
        }
      })
    },
    handleQuickTextStyle() {
      const domNode = this.$refs[this.quickShipTextRef]
      // v-for中ref获取的是数组
      if (Array.isArray(domNode) && domNode?.length > 0) {
        domNode.forEach((item) => {
          if (item.offsetHeight > this.quickShipLineHeight) { item.style.fontSize = this.quickShipFontSizeSmall }
        })
      } else if (domNode) {
        if (domNode.offsetHeight > this.quickShipLineHeight) { domNode.style.fontSize = this.quickShipFontSizeSmall }
      }
    }
  },
}
</script>

<style lang="less">
.old-carts-item_style{
  width: 100% !important;
}
.carts-wrapper {
  display: flex;
  flex-flow: wrap;
  align-items: stretch;
}
.bag-list-content {
  border: none;
  margin-top: 0;
  max-width: 735px;
  .platform-title {
    width: 100%;
    display: flex;
		justify-content: space-between;
    padding-bottom: 10px;
    color: #222;
    strong {
      font-size: 14px;
      color: #303030;
    }
    span {
      font-size: 13px;
      font-weight: bold;
    }
  }
}
.carts-item {
  margin: 10px 0;
}
.drawer-item-effiency {
  padding: 10px 0px 6px 0px;
  margin: 0;
  width: 100%;
}
.carts-item-effiency {
  margin-bottom: 10px;
  .margin-r(5px);
  width: 100px;

  .item-img-content {
    width: 100px;
    height: 100px;
  }

  .cart-promotion-tag label,
  .checkout-cart-label {
    padding: 2px 4px;
    line-height: 1.5;
  }
}

.bag-list__soldout {
  color: #222;
  font-size: 14px;
  margin-top: -6px;
}

// .bag-list__tips {
//   display: table;
//   margin-top: 6px;
//   &_row {
//     display: table-row;
//     &:first-child .bag-list__tips_cell {
//       padding-top: 0;
//     }
//   }
//   &_cell {
//     display: table-cell;
//     text-align: left;
//     padding-top: 14px;
//   }
//   &_icon {
//     margin-right: 16px;
//   }
//   &_text {
//     color: #666;
//     font-family: SF Pro;
//     font-size: 14px;
//     font-weight: 400;
//     text-align: left;
//     line-height: 20px;
//   }
// }
// .shopping-bag__tips-bg{
//   background: #ECFCF3;
//   padding: 7px 8px;
//   margin: 8px 20px 0;
//   border: 1px solid #BADECD;
//   border-radius: 3px;
// }

// .shopping-bag__sensitive {
//   text-align: initial;
//   margin-bottom: 10px;
//   line-height: 17px;
//   padding: 10px 20px;
// }

// .shopping-bag__sensitive_icon {
//   margin-right: .1rem;
// }
// .shopping-bag_newContainer{
//   // display: inline;
//   margin-bottom: 3px;
//   display: flex;
//   flex-wrap: nowrap;
//   align-items: center;
// }
// .shopping-bag__tips-p0{
//   display: inline-flex;
//   padding-right: 0;
//   padding-top: 0;
//   margin-right: 5/75rem;
//   max-width: 40%;
// }
// .shopping-bag__tips_flex{
//   margin-left: 3px;
//   text-align: initial;
//   font-size: 12px;
//   /deep/ span {
//     font-style: italic;
//     font-weight: 590;
//     line-height: normal;
//   }
// }
// .shopping-bage__sensitive_new {
//   color: #666;
//   font-family: "SF Pro";
//   font-size: 12px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 17px;
// }
// .shopping-bag__tips_flex_line_limit {
//   overflow: hidden;  
//   text-overflow: ellipsis; 
//   display: -webkit-box;
//   -webkit-line-clamp: 2;
//   -webkit-box-orient: vertical;
// }
.bag-list__effiency {
  .sui-dialog__wrapper{
    .sui-dialog__body {
      text-align: left;
    }
  }

  .sui-dialog__subtitle {
    font-size: 14px;
    margin-bottom: 16px;
  }
  .bag-list__effiency-total {
    color: @sui_color_main;
  }

  .bag-list-body {
    margin-bottom: 8px;
  }

  .shopping-bag__item-input {
    .sui-input-number__inner {
      width: 41px;
    }
  }
}
.store-business-container{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .store-info-content{
    margin-right: 2px;
    display: flex;
    align-items: flex-end;

    &__icon {
      margin-right: 4px;
    }
  }
  .trend-logo {
    width: 44px;
    height: 14px;
    margin: 0 4px;
  }
  .business-info-content{
    display: flex;
    align-items: center;
    font-size: 10px;
    color:@sui_color_gray_light1;
    position: relative;
    top: 2px;
    cursor: pointer;
    &__icon {
      margin-left: 3px;
    }
    &:hover {
      cursor: pointer;
    }
    span{
      font-size: 10px;
      font-weight: normal;
    }
  }
}
.merchant-content{
  .merchant-content-item{
    margin-bottom: 16px;
    font-size: 14px;
    .item-val{
      color: @sui_color_black;
      font-weight: bold;
    }
    .is-block {
      display: block;
    }
  }
  .desc{
    font-size: 12px;
    margin-bottom: 16px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}

// shoping bage新样式 -- 抽屉
.shopbag-coupon-drawer {
  .sui-drawer__title{
    padding-bottom: 0!important;

  }
  .bag-list-title{
    font-weight: 900;
  }
  .cart-list__promo-tag {
    margin-bottom: 0;
  }
  .sub-title {
    border-bottom: 1px solid #E5E5E5;
    padding: 0 20px 10px;
    .effiency-total {
      color: #000;
      text-align: center;
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .effiency-soldout {
      color: #222;
      text-align: center;
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .sui-drawer__title {
    padding: 20px 0 12px;
  }
  .sui-drawer__body {
    padding: 0 10px 20px;
    margin: 0 10px;
    overflow-y: scroll;
  }
  .shopbag-drawer-list {
    flex: 1 1 100%;
    overflow: hidden;
    .shopbag-list-body {
      overflow: hidden;
    }
  }
  .sui-drawer__body::-webkit-scrollbar{
    width: 5px;
    height: 5px;
  }
  .sui-drawer__body::-webkit-scrollbar-thumb{
    border-radius: 1em;
    background-color: rgba(0,0,0,.3);
  }
  .sui-drawer__body::-webkit-scrollbar-track{
    border-radius: 1em;
    background-color: rgba(0,0,0,.1);
  }
}
.shopbag-list-content {
  .shop-platform-title {
    display: flex;
    justify-content: space-between;
    padding-right: 3px;
    margin-top: 30px;
  }
  .store-business-wrap {
    display: flex;
    align-items: center;
    .store-info-content {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
    }
    .store-name {
      color: #000;
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .store-name-new {
      font-weight: 700px;
      margin-left: 4px;
    }
  }
  .store-product-total {
    color: #222;
    text-align: right;
    font-family: Arial;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .trend-logo {
    width: 44px;
    height: 14px;
    margin-left: 4px;
  }
  .business-info-wrap {
    color: #959595;
    font-family: "SF UI Text";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 6px;
    &__icon {
      margin-left: 3px;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.carts-container{
  margin-top: 5px;
}
</style>
